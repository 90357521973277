import React from "react";
import {
  Box,
  Typography,
  Alert,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Layout } from "../Layouts/Layout";
import { Template as TemplateType } from "../types";
import { TemplateEdit } from "../Components/Template/Edit";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_FORM,
  UPDATE_FORM,
  FormResult,
  UpdateFormResult,
  DELETE_FORM,
  ShareFormResult,
  SHARE_FORM,
} from "../Queries";
import { StatusChip } from "../Components/StatusChip";
import { useErrorBoundary } from "../Contexts/ErrorContext";

// Create a theme with default MUI Alert styles
const defaultAlertTheme = createTheme({
  components: {
    MuiAlert: {
      defaultProps: {
        severity: "warning",
      },
    },
  },
});

export const EditTemplate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { setError } = useErrorBoundary();

  //
  // Queries
  //

  const { loading: formLoading, data: formData } = useQuery<FormResult>(
    GET_FORM,
    {
      variables: { pk: templateId },
      onError: (error) => setError(t("errors.forms.get"), error),
    },
  );

  //
  // Mutations
  //

  const [updateForm, { loading: updateLoading, error: updateError }] =
    useMutation<UpdateFormResult>(UPDATE_FORM, {
      onError: (error) => setError(t("errors.forms.update"), error),
    });

  const [deleteForm] = useMutation<boolean>(DELETE_FORM, {
    refetchQueries: ["GetForms"],
    onError: (error) => setError(t("errors.forms.delete"), error),
  });
  const [shareForm] = useMutation<ShareFormResult>(SHARE_FORM, {
    // refetchQueries: ["GetForm"],
    onError: (error) => setError(t("errors.forms.share"), error),
  });

  const template = formData ? formData.form : undefined;
  const showLoading = formLoading;

  if (!template) {
    return (
      <Layout
        breadcrumbs={[
          { title: t("breadcrumbs.templates"), link: "/templates" },
        ]}
        loading={showLoading}
      >
        {formLoading ? <></> : <>Template not found</>}
      </Layout>
    );
  }

  //
  // Event Handlers
  //

  async function handleTemplateChange(newTemplate: TemplateType) {
    const { data: updateFormResult } = await updateForm({
      variables: { ...newTemplate },
      context: {
        debounceKey: `form-${newTemplate.id}`,
      },
      optimisticResponse: {
        updateForm: { __typename: "Form", ...(newTemplate as any) },
      },
    });
    if (!updateFormResult) {
      return;
    }
  }

  async function handleTemplateDelete(newTemplate: TemplateType) {
    try {
      const { data: deleteFormResult } = await deleteForm({
        variables: { id: newTemplate.id },
      });
      if (!deleteFormResult) {
        return;
      }
      navigate(`/templates`);
    } catch (e) {
      alert("Error deleting template; are you using it in a draft survey?");
    }
  }

  async function handleTemplateShare(
    newTemplate: TemplateType,
    isShared: boolean,
  ) {
    await shareForm({
      variables: { id: newTemplate.id, share: isShared },
    });
  }

  const actionLabel = updateError
    ? "Save Error"
    : updateLoading
      ? "Saving"
      : "Saved";
  const actionStatus = updateError
    ? "error"
    : updateLoading
      ? "warning"
      : "success";

  return (
    <Layout
      breadcrumbs={[
        { title: t("breadcrumbs.templates"), link: "/templates" },
        {
          title: template.isSnapshot
            ? `${template.name} [SNAPSHOT]`
            : template.name,
        },
      ]}
      loading={showLoading}
      actionComponent={<StatusChip label={actionLabel} status={actionStatus} />}
    >
      {template.isUsedInDraftOrScheduled && (
        <ThemeProvider theme={defaultAlertTheme}>
          <Alert sx={{ m: 1 }}>
            You are editing a template that is currently in use by one or more
            surveys that are not yet sent. Changes you make here will be
            reflected in those surveys at the time of sending.
          </Alert>
        </ThemeProvider>
      )}
      <Box sx={{ flexGrow: 1, m: 1 }}>
        <TemplateEdit
          template={template}
          onChange={handleTemplateChange}
          onDelete={template.isMine ? handleTemplateDelete : undefined}
          onShare={template.isMine ? handleTemplateShare : undefined}
        />
      </Box>
    </Layout>
  );
};
