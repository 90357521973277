import React, { useState } from "react";
import { Box, IconButton, Stack } from "@mui/material";
import { Edit, Check, Close } from "@mui/icons-material";

interface EditableFieldProps {
  label: string;
  value: React.ReactNode;
  editMode: React.ReactNode;
  onSave: () => void;
  onCancel: () => void;
  canEdit?: boolean;
}

export const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  editMode,
  onSave,
  onCancel,
  canEdit = true,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    onSave();
  };

  const handleCancel = () => {
    setIsEditing(false);
    onCancel();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Box sx={{ flexGrow: 1 }}>
        {isEditing ? (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box sx={{ flexGrow: 1 }}>{editMode}</Box>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <IconButton size="small" onClick={handleSave} color="primary">
                <Check fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={handleCancel}>
                <Close fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        ) : (
          value
        )}
      </Box>
      {!isEditing && canEdit && (
        <IconButton size="small" onClick={handleEdit}>
          <Edit fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};
