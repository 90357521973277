import React, { useState } from "react";
import { Add, ImportExport } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { Layout } from "../Layouts/Layout";
import { TeamEdit } from "../Components/Team/Edit";
import { Team as TeamType } from "../types";
import { UserList } from "../Components/User/List";
import { SectionSelector } from "../Components/Section/Selector";
import { TeamSelector } from "../Components/Team/Selector";
import { TeamEmpty } from "../Components/Team/Empty";
import {
  GET_SECTIONS,
  SectionsResult,
  GET_GROUPS,
  GroupsResult,
  ADD_GROUP,
  GET_USERS,
  UsersResult,
  UPDATE_GROUP,
  DELETE_GROUP,
  SYNC_COURSE,
  CourseResult,
  GET_COURSE,
  SET_COURSE_MODE,
  SET_GROUP_LEADER,
} from "../Queries";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { ImportModal } from "../Components/Team/ImportModal";
import { LoadingButton } from "@mui/lab";
import { useErrorBoundary } from "../Contexts/ErrorContext";

export const RosterEmpty = () => {
  return (
    <Card>
      <CardHeader title="Great job!" />
      <CardContent>
        <Typography>
          You have successfully assigned your entire roster.
        </Typography>
      </CardContent>
    </Card>
  );
};

function floatEmptyTeams(teams: TeamType[]): TeamType[] {
  const floated = [...teams].sort((x, y) => {
    if (x.userIds.length === 0) {
      return -1;
    }
    if (y.userIds.length === 0) {
      return 1;
    }
    return 0;
  });
  return floated;
}

export const Teams = () => {
  const [t] = useTranslation();
  const { setError } = useErrorBoundary();

  //
  // State
  //

  const [importOpen, setImportOpen] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [sectionId, setSectionId] = useState(undefined as string | undefined);
  const [selected, setSelected] = useState<string[]>([]);

  //
  // Queries
  //

  const { loading: sectionsLoading, data: sectionsData } =
    useQuery<SectionsResult>(GET_SECTIONS, {
      variables: { filters: {} },
      onError: (error) => setError(t("errors.sections.list"), error),
    });

  const {
    loading: courseLoading,
    data: courseData,
    refetch: refetchCourse,
  } = useQuery<CourseResult>(GET_COURSE, {
    onError: (error) => setError(t("errors.courses.get"), error),
  });

  const { loading: groupsLoading, data: groupsData } = useQuery<GroupsResult>(
    GET_GROUPS,
    {
      variables: {
        filters: {
          // section: sectionId ? sectionId : undefined,
        },
      },
      onError: (error) => setError(t("errors.groups.list"), error),
    },
  );

  const teams = groupsData ? groupsData.groups : undefined;

  const { loading: usersLoading, data: usersData } = useQuery<UsersResult>(
    GET_USERS,
    {
      variables: {
        filters: {
          sections: sectionId ? sectionId : undefined,
          // unassigned: true
        },
      },
      onError: (error) => setError(t("errors.users.list"), error),
    },
  );

  //
  // Mutations
  //

  const [addGroup] = useMutation(ADD_GROUP, {
    refetchQueries: ["GetGroups"],
    onError: (error) => setError(t("errors.groups.create"), error),
  });
  const [updateGroup] = useMutation(UPDATE_GROUP, {
    //refetchQueries: ["GetGroups"],
    onError: (error) => setError(t("errors.groups.update"), error),
  });
  const [deleteGroup] = useMutation(DELETE_GROUP, {
    refetchQueries: ["GetGroups"],
    onError: (error) => setError(t("errors.groups.delete"), error),
  });
  const [syncCourse] = useMutation(SYNC_COURSE, {
    refetchQueries: ["GetGroups", "GetSections"],
    onError: (error) => setError(t("errors.courses.sync"), error),
  });
  const [setCourseMode] = useMutation(SET_COURSE_MODE, {
    // refetchQueries: [""]
    onError: (error) => setError(t("errors.courses.setMode"), error),
  });
  const [setTeamLeader] = useMutation(SET_GROUP_LEADER, {
    // refetchQueries: [""]
    onError: (error) => setError(t("errors.groups.setLeader"), error),
  });

  const course = courseData ? courseData.course : undefined;
  const sections = sectionsData ? sectionsData.sections : undefined;
  const section = sections
    ? sections.find((target) => target.id === sectionId)
    : undefined;

  if (!sectionsLoading && !sections) {
    throw new Error("No sections found");
  }

  const users = usersData?.users.filter((user) => user.isLearner);

  let unassignedUsers = users || [];

  if (teams) {
    teams.forEach((team) => {
      unassignedUsers = unassignedUsers.filter(
        (user) => team.userIds.indexOf(user.id) < 0,
      );
    });
  }

  //
  // Event Handlers
  //

  function handleAddTeam() {
    addGroup({
      variables: {
        name: "New team",
        // sectionId,
      },
    });
  }

  function handleTeamDelete(team: TeamType) {
    deleteGroup({
      variables: team,
    });
  }

  function handleTeamChange(team: TeamType) {
    updateGroup({
      variables: team,
      context: {
        debounceKey: `team.${team.id}`,
      },
      optimisticResponse: {
        updateGroup: {
          id: team.id,
          __typename: "Group",
          name: team.name,
          userIds: team.userIds,
        },
      },
    });
  }

  function handleImportTeams() {
    if (course?.fullSyncEnabled && course?.fullSyncReady) {
      importTeams();
    } else {
      setImportOpen(true);
    }
  }

  function handleImportClose() {
    setImportOpen(false);
  }

  function handleImportConnected() {
    refetchCourse();
  }

  async function handleImportEnable(enabled: boolean) {
    await setCourseMode({
      variables: {
        fullSync: enabled,
      },
    });

    setImportOpen(false);
    importTeams();
  }

  function handleAssignment(userIds: string[], team: TeamType) {
    handleTeamChange({ ...team, userIds: [...team.userIds, ...userIds] });
    setSelected([]);
  }

  function handleSetLeader(user: string, team: TeamType) {
    setTeamLeader({
      variables: {
        id: team.id,
        leaderId: user,
      },
    });
  }

  //
  // Service
  //

  async function importTeams() {
    setIsImporting(true);
    await syncCourse();
    setIsImporting(false);
  }

  const showLoading = sectionsLoading || groupsLoading || usersLoading;

  return (
    <Layout
      breadcrumbs={[
        { title: t("breadcrumbs.teams") },
        { title: t("breadcrumbs.teamList") },
      ]}
      loading={showLoading}
    >
      {importOpen && course && (
        <ImportModal
          course={course}
          onClose={handleImportClose}
          onConnect={handleImportConnected}
          onEnable={handleImportEnable}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {!sectionsLoading && sections ? (
          <SectionSelector
            sections={sections}
            selected={section}
            onChange={(section) => setSectionId(section ? section.id : "")}
          />
        ) : (
          <>Sections loading...</>
        )}
        <Typography sx={{ ml: 2 }}>
          {(teams ?? []).length} Team{teams?.length === 1 ? "" : "s"}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <Tooltip
            title={
              courseLoading || course === undefined
                ? ""
                : !course.fullSyncCapable
                  ? "Your LMS isn't configured for full API acccess; please contact an administrator to enable full sync"
                  : "Import teams and sections from your LMS"
            }
          >
            <span>
              <LoadingButton
                disabled={
                  courseLoading ||
                  course === undefined ||
                  !course.fullSyncCapable
                }
                variant="contained"
                aria-label="import teams"
                sx={{ mr: 1 }}
                endIcon={<ImportExport />}
                onClick={handleImportTeams}
                loading={isImporting}
              >
                {isImporting ? "Importing..." : "Import Teams"}
              </LoadingButton>
            </span>
          </Tooltip>
          <Button
            variant="contained"
            aria-label="add team"
            endIcon={<Add />}
            onClick={handleAddTeam}
          >
            Add Team
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          {usersLoading || users === undefined ? (
            <>Loading users!</>
          ) : (
            <>
              {unassignedUsers && unassignedUsers.length > 0 && (
                <Card>
                  <CardHeader
                    title="Roster"
                    action={
                      selected.length > 0 && (
                        <TeamSelector
                          teams={teams ? teams : []}
                          label="Assign team members"
                          onChange={(team) => handleAssignment(selected, team)}
                        />
                      )
                    }
                  />
                  <CardContent>
                    <UserList
                      users={unassignedUsers}
                      setSelected={setSelected}
                      selected={selected}
                    />
                  </CardContent>
                </Card>
              )}
              {unassignedUsers.length === 0 && <RosterEmpty />}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {groupsLoading || teams === undefined ? (
            <>Teams loading!</>
          ) : (
            <>
              {floatEmptyTeams(teams).map((team, index) => (
                <TeamEdit
                  key={index}
                  team={team}
                  users={users ? users : []}
                  onAssign={handleAssignment}
                  onChange={handleTeamChange}
                  onDelete={handleTeamDelete}
                  onSetLeader={handleSetLeader}
                />
              ))}
              {teams.length === 0 && (
                <TeamEmpty onAddTeamClick={handleAddTeam} />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};
