import React, { createContext, useContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

interface ErrorContextType {
  error: Error | null;
  message: string | null;
  setError: (message: string | null, error: Error | null) => void | null;
}

const ErrorContext = createContext<ErrorContextType>({
  error: null,
  message: null,
  setError: () => null,
});

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  //
  // State
  //

  const [errorObj, setError] = useState<{
    message: string | null;
    error: Error | null;
  } | null>(null);

  // Reset reported error after timeout
  useEffect(() => {
    const timerRef = setTimeout(() => {
      setError(null);
    }, 6000);

    return () => clearTimeout(timerRef);
  }, [errorObj]);

  return (
    <ErrorContext.Provider
      value={{
        error: errorObj?.error || null,
        message: errorObj?.message || null,
        setError: (message: string | null, error: Error | null) => {
          console.error(error);
          setError({ message, error });
        },
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorObj?.message}
        autoHideDuration={6000}
      >
        <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
          {errorObj?.message}
        </Alert>
      </Snackbar>
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorBoundary = () => {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error("useErrorBoundary must be used within a ErrorProvider");
  }
  return context;
};
