import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Switch,
  FormControlLabel,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { EditableField } from "./EditableField";
import { TemplateSelector } from "../Template/Selector";
import { AssignmentSelector } from "../Assignment/Selector";
import { AssignmentGradingSelector } from "../AssignmentGrading/Selector";
import { GroupCategorySelector } from "../GroupCategory/Selector";
import { RatingProportionSlider } from "../RatingProportion/Slider";
import {
  Campaign,
  Template,
  CampaignGradingMode,
  GroupCategory,
  CampaignState,
} from "../../types";

interface EditableTemplateProps {
  campaign: Campaign;
  template: Template | undefined;
  templates: Template[];
  onChange: (campaign: Campaign, activityLabel: string) => void;
}

export const EditableTemplate: React.FC<EditableTemplateProps> = ({
  campaign,
  template,
  templates,
  onChange,
}) => {
  const [t] = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<
    Template | undefined
  >(template);

  const handleSave = () => {
    if (selectedTemplate) {
      onChange(
        { ...campaign, formId: selectedTemplate.id },
        t("campaigns.events.templateChange", {
          template: selectedTemplate.name,
        }),
      );
    }
  };

  return (
    <EditableField
      label={t("templates.template")}
      value={
        template ? (
          <Link to={`/templates/${template.id}`} style={{ color: "inherit" }}>
            {template.name}
          </Link>
        ) : (
          "No template selected"
        )
      }
      editMode={
        <TemplateSelector
          selected={selectedTemplate}
          templates={templates}
          onChange={setSelectedTemplate}
        />
      }
      onSave={handleSave}
      onCancel={() => setSelectedTemplate(template)}
      canEdit={campaign.state === CampaignState.Scheduled}
    />
  );
};

interface EditableAssignmentProps {
  campaign: Campaign;
  onChange: (campaign: Campaign, activityLabel: string) => void;
}

export const EditableAssignment = ({
  campaign,
  onChange,
}: EditableAssignmentProps) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(campaign.assignmentEnabled);

  const handleSave = () => {
    onChange(
      { ...campaign, assignmentEnabled: enabled },
      t("activity.assignmentSettingsUpdated"),
    );
  };

  const handleCancel = () => {
    setEnabled(campaign.assignmentEnabled);
  };

  const canEdit = campaign.state === CampaignState.Scheduled;

  return (
    <EditableField
      label={t("assignments.selector")}
      value={enabled ? t("campaigns.yes") : t("campaigns.no")}
      editMode={
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{t("campaigns.no")}</span>
          <Switch
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
            size="small"
          />
          <span>{t("campaigns.yes")}</span>
        </Stack>
      }
      onSave={handleSave}
      onCancel={handleCancel}
      canEdit={canEdit}
    />
  );
};

interface EditableGradingProps {
  campaign: Campaign;
  onChange: (campaign: Campaign, activityLabel: string) => void;
}

export const EditableGrading: React.FC<EditableGradingProps> = ({
  campaign,
  onChange,
}) => {
  const [t] = useTranslation();
  const [gradingMode, setGradingMode] = useState<
    CampaignGradingMode | undefined
  >(campaign.assignmentGradingMode);

  const handleSave = () => {
    if (gradingMode) {
      onChange(
        { ...campaign, assignmentGradingMode: gradingMode },
        t("campaigns.events.assignmentGradingChange", {
          assignmentGradingMode: gradingMode,
        }),
      );
    }
  };

  return (
    <EditableField
      label={t("assignmentsGrading.selector")}
      value={
        campaign.assignmentEnabled
          ? t(`assignmentsGrading.mode.${campaign.assignmentGradingMode}`)
          : "n/a"
      }
      editMode={
        <AssignmentGradingSelector
          selected={gradingMode}
          onChange={setGradingMode}
        />
      }
      onSave={handleSave}
      onCancel={() => setGradingMode(campaign.assignmentGradingMode)}
      canEdit={
        campaign.state === CampaignState.Scheduled && campaign.assignmentEnabled
      }
    />
  );
};

interface EditablePointsProps {
  campaign: Campaign;
  onUpdatePoints: (campaignId: string, points: string) => void;
}

export const EditablePoints: React.FC<EditablePointsProps> = ({
  campaign,
  onUpdatePoints,
}) => {
  const [t] = useTranslation();
  const [points, setPoints] = useState(campaign.assignmentPoints || "");

  const handleSave = () => {
    onUpdatePoints(campaign.id, points);
  };

  return (
    <EditableField
      label={t("assignments.points")}
      value={campaign.assignmentEnabled ? campaign.assignmentPoints : "n/a"}
      editMode={
        <TextField
          size="small"
          fullWidth
          value={points}
          onChange={(e) => setPoints(e.target.value)}
          type="number"
        />
      }
      onSave={handleSave}
      onCancel={() => setPoints(campaign.assignmentPoints || "")}
      canEdit={
        [CampaignState.Active, CampaignState.Scheduled].includes(
          campaign.state,
        ) && campaign.assignmentEnabled
      }
    />
  );
};

interface EditableRatingProportionProps {
  campaign: Campaign;
  onChange: (campaign: Campaign, activityLabel: string) => void;
}

export const EditableRatingProportion: React.FC<EditableRatingProportionProps> =
  ({ campaign, onChange }) => {
    const [t] = useTranslation();
    const [proportion, setProportion] = useState(
      campaign.assignmentRatingProportion || 0,
    );

    const handleSave = () => {
      onChange(
        { ...campaign, assignmentRatingProportion: proportion },
        t("campaigns.events.assignmentRatingProportionChange", {
          assignmentRatingProportion: proportion,
        }),
      );
    };

    const displayValue =
      campaign.assignmentEnabled && campaign.assignmentGradingMode === "FA"
        ? `${campaign.assignmentRatingProportion}% based on ratings, ${
            100 - (campaign.assignmentRatingProportion || 0)
          }% based on completion`
        : "n/a";

    return (
      <EditableField
        label={t("assignments.ratingProportion")}
        value={displayValue}
        editMode={
          <RatingProportionSlider
            initialValue={proportion}
            onChangeCommitted={(value) => setProportion(value)}
          />
        }
        onSave={handleSave}
        onCancel={() => setProportion(campaign.assignmentRatingProportion || 0)}
        canEdit={
          campaign.state === CampaignState.Scheduled &&
          campaign.assignmentEnabled &&
          campaign.assignmentGradingMode === "FA"
        }
      />
    );
  };

interface EditableGroupCategoryProps {
  campaign: Campaign;
  categories: GroupCategory[];
  onSetGroupCategory: (
    campaign: Campaign,
    groupcategory: GroupCategory | undefined,
  ) => void;
}

export const EditableGroupCategory: React.FC<EditableGroupCategoryProps> = ({
  campaign,
  categories,
  onSetGroupCategory,
}) => {
  const [t] = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<
    GroupCategory | undefined
  >(campaign.groupcategory);

  const handleSave = () => {
    onSetGroupCategory(campaign, selectedCategory);
  };

  return (
    <EditableField
      label={t("campaigns.teamSet")}
      value={campaign.groupcategory?.name || "No team set selected"}
      editMode={
        <GroupCategorySelector
          selected={selectedCategory}
          categories={categories}
          onChange={(groupcategory: GroupCategory | undefined) =>
            setSelectedCategory(groupcategory)
          }
        />
      }
      onSave={handleSave}
      onCancel={() => setSelectedCategory(campaign.groupcategory)}
      canEdit={campaign.state === CampaignState.Scheduled}
    />
  );
};

interface EditableLoopStudentProps {
  campaign: Campaign;
  onChange: (campaign: Campaign, activityLabel: string) => void;
}

export const EditableLoopStudent: React.FC<EditableLoopStudentProps> = ({
  campaign,
  onChange,
}) => {
  const [t] = useTranslation();
  const [loopStudent, setLoopStudent] = useState(campaign.loopStudent);

  const handleSave = () => {
    onChange(
      { ...campaign, loopStudent },
      t("campaigns.events.loopStudentChange", {
        enabled: loopStudent ? "enabled" : "disabled",
      }),
    );
  };

  return (
    <EditableField
      label={t("campaigns.loopStudent")}
      value={campaign.loopStudent ? t("campaigns.yes") : t("campaigns.no")}
      editMode={
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{t("campaigns.no")}</span>
          <Switch
            checked={loopStudent}
            onChange={(e) => setLoopStudent(e.target.checked)}
            size="small"
          />
          <span>{t("campaigns.yes")}</span>
        </Stack>
      }
      onSave={handleSave}
      onCancel={() => setLoopStudent(campaign.loopStudent)}
      canEdit={campaign.state === CampaignState.Scheduled}
    />
  );
};

interface EditableLoopInstructorProps {
  campaign: Campaign;
  onChange: (campaign: Campaign, activityLabel: string) => void;
}

export const EditableLoopInstructor: React.FC<EditableLoopInstructorProps> = ({
  campaign,
  onChange,
}) => {
  const [t] = useTranslation();
  const [loopInstructor, setLoopInstructor] = useState(campaign.loopInstructor);

  const handleSave = () => {
    onChange(
      { ...campaign, loopInstructor },
      t("campaigns.events.loopInstructorChange", {
        enabled: loopInstructor ? "enabled" : "disabled",
      }),
    );
  };

  return (
    <EditableField
      label={t("campaigns.loopInstructor")}
      value={campaign.loopInstructor ? t("campaigns.yes") : t("campaigns.no")}
      editMode={
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{t("campaigns.no")}</span>
          <Switch
            checked={loopInstructor}
            onChange={(e) => setLoopInstructor(e.target.checked)}
            size="small"
          />
          <span>{t("campaigns.yes")}</span>
        </Stack>
      }
      onSave={handleSave}
      onCancel={() => setLoopInstructor(campaign.loopInstructor)}
      canEdit={campaign.state === CampaignState.Scheduled}
    />
  );
};
