import React, { useEffect } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ThinLayout } from "../Layouts/ThinLayout";
import { ResponseEdit } from "../Components/Response/Edit";
import {
  ItemType,
  PointsAllocationMode,
  ResponseSubjectType,
  Response as ResponseType,
} from "../types";
import { Publish } from "@mui/icons-material";
import { BlingCheckmark } from "../Components/BlingCheckmark";
import { useQuery, useMutation } from "@apollo/client";
import {
  CLAIM_LICENSE,
  CampaignsResult,
  GET_CAMPAIGNS,
  GET_RESPONSE,
  GET_USERS,
  ResponseResult,
  UPDATE_CAMPAIGN,
  UPDATE_RESPONSE,
  UsersResult,
} from "../Queries";
import LicenseGate from "../Components/License/Gate";
import { useErrorBoundary } from "../Contexts/ErrorContext";
import { useTranslation } from "react-i18next";

export const UserResponse = () => {
  const [searchParams] = useSearchParams();
  const { setError } = useErrorBoundary();
  const [t] = useTranslation();

  // Decompose query params
  const key = searchParams.get("key");
  const responseKeyBlob = atob(key || "");
  const responseParts = responseKeyBlob.split(":");
  const responseId = responseParts[0];
  const responseToken = responseParts[1];

  //
  // Queries
  //

  const { loading: responseLoading, data: responseData } =
    useQuery<ResponseResult>(GET_RESPONSE, {
      variables: { pk: responseId },
      context: {
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
      onError: (error) => setError(t("errors.responses.get"), error),
    });

  const { loading: usersLoading, data: usersData } = useQuery<UsersResult>(
    GET_USERS,
    {
      variables: {
        filters: {},
      },
      context: {
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
      onError: (error) => setError(t("errors.users.list"), error),
    },
  );

  const { loading: campaignsLoading, data: campaignsData } =
    useQuery<CampaignsResult>(GET_CAMPAIGNS, {
      variables: {
        filters: {},
      },
      context: {
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
      onError: (error) => setError(t("errors.campaigns.list"), error),
    });

  //
  // Mutations
  //

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    // refetchQueries: ["GetCampaign"],
    onError: (error) => setError(t("errors.campaigns.update"), error),
  });

  const [updateResponse] = useMutation(UPDATE_RESPONSE, {
    // refetchQueries: ["GetResponse"],
    onError: (error) => setError(t("errors.responses.update"), error),
  });

  const [claimLicense, { loading: claimPending }] = useMutation(CLAIM_LICENSE, {
    refetchQueries: ["MyRole"],
    onError: (error) => setError(t("errors.licenses.claim"), error),
  });

  //
  // Effects
  //

  const response = responseData ? responseData.response : undefined;

  useEffect(() => {
    if (response?.submitted) {
      const audio = new Audio("/response-submitted.mp3");
      audio.play();
    }
  }, [response?.submitted]);

  const users = usersData ? usersData.users : undefined;
  const author =
    users !== undefined
      ? users.find((user) => response?.authorId === user.id)
      : undefined;

  const campaigns = campaignsData ? campaignsData.campaigns : [];
  const campaign = campaigns.find(
    (campaign) => response?.campaignId === campaign.id,
  );
  const showLoading = responseLoading || usersLoading || campaignsLoading;
  const template = response ? response.form : null;
  const subjects =
    users !== undefined
      ? users.filter(
          (subject) =>
            (
              response?.responseSubjects.map((sr) => sr.subjectUserId) || []
            ).indexOf(subject.id) >= 0,
        )
      : [];

  if (!response || !author || !campaign || !template) {
    return (
      <ThinLayout title="User Response" loading={showLoading}>
        {showLoading ? (
          <></>
        ) : (
          <>We could not find the response associated with this URL.</>
        )}
      </ThinLayout>
    );
  }

  if (response.submitted) {
    return (
      <ThinLayout title="">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            opacity: 1,
            animation: "fade 2s linear",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              You&apos;re all done! Your submission has been received.
            </Typography>
            <BlingCheckmark
              active={false}
              completed={true}
              size="large"
              divProps={{ style: { marginTop: 10 } }}
            />
          </Box>
        </Box>
      </ThinLayout>
    );
  }

  //
  // Event Handlers
  //

  async function handleResponseChange(response: ResponseType) {
    await updateResponse({
      variables: {
        id: response.id,
        submitted: response.submitted,
        submittedDate: new Date().toISOString(),
        responseSubjects: response.responseSubjects,
      },
      context: {
        debounceKey: `response-${response.id}`,
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
      optimisticResponse: {
        updateResponse: {
          __typename: "Response",
          ...(response as any),
        },
      },
    });
  }

  function handleSubmit() {
    if (!response || !campaign) {
      return;
    }
    handleResponseChange({ ...response, submitted: true });
  }

  // BEGIN POINTS VALIDATION
  const pointsItems = template.items.filter(
    (item) => item.itemType === ItemType.Points,
  );
  const pointItemIds = pointsItems.map((pi) => pi.id);
  const userSubjects = response.responseSubjects.filter(
    (rs) => rs.subjectType === ResponseSubjectType.Peer,
  );
  const subjectPointAnswers = userSubjects
    .map((rs) =>
      rs.answers.filter((answer) => pointItemIds.includes(answer.itemId)),
    )
    .flat();

  // Group answers by item ID for easier validation
  const answersByItem = pointItemIds.reduce(
    (acc, itemId) => {
      acc[itemId] = subjectPointAnswers.filter((ans) => ans.itemId === itemId);
      return acc;
    },
    {} as Record<string, typeof subjectPointAnswers>,
  );

  // Check point totals match expected totals
  const pointTotalDiffs = pointsItems.map(
    (pi) =>
      (pi.scale ? pi.scale * userSubjects.length : 0) -
      answersByItem[pi.id].reduce((prev, ans) => prev + (ans.value || 0), 0),
  );

  // Validate variance and uniqueness requirements for each points item
  const pointConstraintsValidated = pointsItems.every((pi) => {
    const itemAnswers = answersByItem[pi.id].map((a) => a.value || 0);

    // Skip if no answers yet
    if (itemAnswers.length === 0) return false;

    // Check minimum variance if specified
    if (pi.minVariance) {
      const variance = Math.max(...itemAnswers) - Math.min(...itemAnswers);
      if (variance < pi.minVariance) return false;
    }

    // Check uniqueness if required
    if (pi.pointsAllocationMode === PointsAllocationMode.UniqueAllocation) {
      const uniqueValues = new Set(itemAnswers);
      if (uniqueValues.size !== itemAnswers.length) return false;
    }

    return true;
  });

  const pointsMatched = pointTotalDiffs.every((diff) => diff === 0);
  const pointsValidated =
    pointsItems.length === 0 ||
    (pointsItems.length * userSubjects.length === subjectPointAnswers.length &&
      pointsMatched &&
      pointConstraintsValidated);
  // END POINTS VALIDATION

  const responsesMissing = response.responseSubjects.find(
    (sr) => !sr.completed,
  );
  const canSubmit = !responsesMissing && pointsValidated;

  return (
    <ThinLayout title={campaign.name} loading={showLoading}>
      <LicenseGate jwt={responseToken}>
        <ResponseEdit
          response={response}
          author={author}
          subjects={subjects}
          formItems={template?.items || []}
          onChange={handleResponseChange}
          instructions={template.instructions}
        />
        <Tooltip
          title={
            !canSubmit ? "All tabs must be completed before you can submit" : ""
          }
        >
          <span>
            <Button
              variant="contained"
              endIcon={<Publish />}
              onClick={handleSubmit}
              disabled={Boolean(!canSubmit)}
              sx={{ ml: 2 }}
            >
              Submit
            </Button>
          </span>
        </Tooltip>
      </LicenseGate>
    </ThinLayout>
  );
};
