import { getUnique } from "./DataTransform";
import {
  Campaign,
  CampaignActivity,
  CampaignState,
  Data,
  ItemOption,
  FormItem,
  ItemSubjectType,
  ItemType,
  Response,
  Section,
  ResponseSubject,
  ResponseSubjectType,
  Team,
  Template,
  User,
  Answer,
  ItemVisibility,
  ItemRecipientType,
  PointsAllocationMode,
  QuestionType,
} from "./types";
import randomUUID from "./UUID";

export const options = [
  {
    id: "",
    label: "Strongly Agree",
    value: 5,
    order: 0,
  },
  {
    id: "",
    label: "Agree",
    value: 4,
    order: 1,
  },
  {
    id: "",
    label: "Neither agree nor disagree",
    value: 3,
    order: 2,
  },
  {
    id: "",
    label: "Disagree",
    value: 2,
    order: 3,
  },
  {
    id: "",
    label: "Strongly Disagree",
    value: 1,
    order: 4,
  },
];

export const yesno = [
  {
    id: "",
    label: "Yes",
    value: 1,
    order: 0,
  },
  {
    id: "",
    label: "No",
    value: 0,
    order: 1,
  },
];

export const questions: FormItem[] = [
  {
    id: randomUUID(),
    label: "Does this teammate listen well to others' suggestions?",
    category: "Communication",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label: "Does the teammate communicate effectively with other students?",
    category: "Communication",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label:
      "Does the teammate ask for more information if they do not understand something?",
    category: "Communication",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label:
      "Does the teammate communicate well in writing with good grammar and spelling?",
    category: "Communication",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label: "This teammate has great leadership potential.",
    category: "Leadership",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label:
      "This teammate is very confident in their role as group leader. They are able to get the most out of everyone on the team.",
    category: "Leadership",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label: "This teammate has the ability to lead small groups.",
    category: "Leadership",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
  {
    id: randomUUID(),
    label: "This teammate is highly effective at leading work groups.",
    category: "Leadership",
    itemType: ItemType.Choice,
    optional: false,
    options,
    order: 0,
    recipientType: ItemRecipientType.TeamMembers,
    subjectType: ItemSubjectType.PeerSelf,
  },
];

export const users: User[] = [
  {
    id: randomUUID(),
    email: "",
    headshot:
      "https://dxtww98cr0lum.cloudfront.net/uploads/2104/images/bios/david-comisford-61b705d9d883a.jfif",
    firstName: "David",
    lastName: "Comisford",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot:
      "https://uploads.toptal.io/profile_photo/image/user/1685897/large_9305550f553c70991084637afbb02c59.jpg",
    firstName: "Christian",
    lastName: "Lent",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot:
      "https://uploads.toptal.io/profile_photo/image/user/1581574/large_e35a1a98468bd5e5049a1da4313c0ffa.jpg",
    firstName: "Derek",
    lastName: "Edwards",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/alexa-roberts.jpg",
    firstName: "Alexa",
    lastName: "Roberts",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/alexander-hipp.jpg",
    firstName: "Alexander",
    lastName: "Hipp",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/bethany-nackos.jpg",
    firstName: "Bethany",
    lastName: "Nackos",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/brooke-stiles.jpg",
    firstName: "Brooke",
    lastName: "Stiles",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/charles-deluvio.jpg",
    firstName: "Charles",
    lastName: "Deluvio",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/emilia-brody.jpg",
    firstName: "Emilia",
    lastName: "Brody",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/nicolas-horn.jpg",
    firstName: "Nicolas",
    lastName: "Horn",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/sarah-brown.jpg",
    firstName: "Sarah",
    lastName: "Brown",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/taras-shypka.jpg",
    firstName: "Taras",
    lastName: "Shypka",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/victor-knisely.jpg",
    firstName: "Victor",
    lastName: "Knisely",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/john-lossing.jpg",
    firstName: "John",
    lastName: "Lossing",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/kyle-chris.jpg",
    firstName: "Kyle",
    lastName: "Chris",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/kyle-manning.jpg",
    firstName: "Kyle",
    lastName: "Manning",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/len-shodes.jpg",
    firstName: "Len",
    lastName: "Shodes",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/mattie-jones.jpg",
    firstName: "Mattie",
    lastName: "Jones",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/max-smith.jpg",
    firstName: "Max",
    lastName: "Smith",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/missy-stanford-smith.jpg",
    firstName: "Missy",
    lastName: "Stanford-Smith",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/philip-meng.jpg",
    firstName: "Philip",
    lastName: "Meng",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/rachel-clemmons.jpg",
    firstName: "Rachel",
    lastName: "Clemmons",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/rebecca-lennon.jpg",
    firstName: "Rebecca",
    lastName: "Lennon",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/shale-benson.jpg",
    firstName: "Shale",
    lastName: "Benson",
    ssoId: "",
    isLearner: true,
  },
];

export const templates: Template[] = [
  {
    id: randomUUID(),
    name: "General 360 Feedback",
    modified: new Date().toISOString(),
    items: questions,
    active: true,
    institutionCreated: true,
    isDirectShare: false,
    isGlobal: false,
    isShared: false,
    isSnapshot: false,
    isMine: false,
    isUsedInDraftOrScheduled: false,
    instructions:
      "Please provide feedback for each of your teammates for this review cycle.",
    user: users[0],
  },
  {
    id: randomUUID(),
    name: "CSE201 360 Feedback",
    modified: new Date().toISOString(),
    items: questions,
    active: true,
    institutionCreated: false,
    isDirectShare: false,
    isGlobal: false,
    isShared: false,
    isSnapshot: false,
    isMine: true,
    isUsedInDraftOrScheduled: false,
    instructions:
      "Please provide feedback for each of your teammates for this review cycle.",
    user: users[0],
  },
];

export const sections: Section[] = [
  {
    id: randomUUID(),
    name: "MWF 230",
    course: {
      id: "1",
      name: "CSE201",
    },
    userIds: users.map((user) => user.id),
  },
  {
    id: randomUUID(),
    name: "MWF 430",
    course: {
      id: "1",
      name: "CSE201",
    },
    userIds: [],
  },
];

export const teams: Team[] = [
  {
    id: randomUUID(),
    name: "Team A",
    userIds: [users[0].id, users[1].id, users[2].id, users[3].id],
  },
  {
    id: randomUUID(),
    name: "Team B",
    userIds: [users[4].id, users[5].id, users[6].id, users[7].id],
  },
  {
    id: randomUUID(),
    name: "Team C",
    userIds: [users[8].id, users[9].id, users[10].id, users[11].id],
  },
  {
    id: randomUUID(),
    name: "Team D",
    userIds: [users[12].id, users[13].id, users[14].id, users[15].id],
  },
];

export const campaigns: Campaign[] = [];

const weightedRandom = (items: number[], weights: number[]) => {
  if (items.length !== weights.length) {
    throw new Error("Items and weights must be of the same size");
  }

  if (!items.length) {
    throw new Error("Items must not be empty");
  }

  // Preparing the cumulative weights array.
  // For example:
  // - weights = [1, 4, 3]
  // - cumulativeWeights = [1, 5, 8]
  const cumulativeWeights: number[] = [];
  for (let i = 0; i < weights.length; i += 1) {
    cumulativeWeights[i] = weights[i] + (cumulativeWeights[i - 1] || 0);
  }

  // Getting the random number in a range of [0...sum(weights)]
  // For example:
  // - weights = [1, 4, 3]
  // - maxCumulativeWeight = 8
  // - range for the random number is [0...8]
  const maxCumulativeWeight = cumulativeWeights[cumulativeWeights.length - 1];
  const randomNumber = maxCumulativeWeight * Math.random();

  // Picking the random item based on its weight.
  // The items with higher weight will be picked more often.
  for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
    if (cumulativeWeights[itemIndex] >= randomNumber) {
      return items[itemIndex];
    }
  }
};

const standardOptions = [1, 2, 3, 4, 5];
const standardWeights = [0.0125, 0.025, 0.1, 0.45, 0.4125];

export const responses: Response[] = [];

export const data: Data = syncResponses({
  sections,
  teams,
  templates,
  users,
  campaigns,
  responses,
});

export function getNewTeam(): Team {
  return {
    id: randomUUID(),
    name: "New Team",
    userIds: [],
  };
}

export function getNewQuestion(itemType: ItemType, order = 0) {
  return {
    id: randomUUID(),
    //value: 3,
    label: "",
    category: "New Category",
    itemType,
    optional: false,
    minVariance: 0,
    options,
    order,
    recipientType: ItemRecipientType.TeamMembers,
    scale: itemType === ItemType.Points ? 100 : 0,
    subjectType: ItemSubjectType.PeerSelf,
    visibility:
      itemType === ItemType.Text || itemType === ItemType.Points
        ? ItemVisibility.Private
        : ItemVisibility.Releasable,
    pointsAllocationMode: PointsAllocationMode.MinimumVariance,
    questionType: QuestionType.Generic,
  };
}

export function getNewTemplate() {
  return {
    id: randomUUID(),
    active: false,
    lastUpdated: new Date().toISOString(),
    label: "New Template",
    institutionCreated: false,
    isDirectShare: false,
    isGlobal: false,
    isShared: false,
    isSnapshot: false,
    isMine: true,
    isUsedInDraftOrScheduled: false,
    instructions: "New instructions",
    questions: [getNewQuestion(ItemType.Choice)],
  };
}

export function getCampaignActivity(label: string): CampaignActivity {
  return {
    id: randomUUID(),
    label,
    created: new Date().toISOString(),
  };
}

export function findCampaignActivity(
  campaign: Campaign,
  label: string,
): CampaignActivity | undefined {
  const sorted = [...campaign.activity].sort((y, x) =>
    x.created.localeCompare(y.created),
  );
  return sorted.find((x) => x.label === label);
}

/**
 * Sort campaigns by activity and order by most recent activity
 *
 * @param campaigns
 * @param label
 * @returns
 */
export function sortByActivity(
  campaigns: Campaign[],
  label: string,
  filterByState?: CampaignState,
): Campaign[] {
  if (filterByState) {
    campaigns = campaigns.filter((x) => x.state === filterByState);
  }

  const withSortable = campaigns.map((campaign) => ({
    campaign,
    sortable: findCampaignActivity(campaign, label),
  }));

  withSortable.sort((x, y) => {
    if (!x.sortable?.created) {
      return -1;
    }
    if (!y.sortable?.created) {
      return 1;
    }
    return y.sortable.created.localeCompare(x.sortable.created);
  });

  return withSortable.map((x) => x.campaign);
}

export function getNewCampaign(sectionId: string, templateId: string) {
  return {
    id: randomUUID(),
    state: CampaignState.Draft,
    label: "New Survey",
    sectionId,
    templateId,
    activity: [getCampaignActivity("Campaign Created")],
  };
}

export const extraUsers: User[] = [
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/george-angel.jpg",
    firstName: "George",
    lastName: "Angel",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/joseph-gonzalez.jpg",
    firstName: "Joseph",
    lastName: "Gonzalez",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/julia-faust.jpg",
    firstName: "Julia",
    lastName: "Faust",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/julian-wan.jpg",
    firstName: "Julian",
    lastName: "Wan",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/laura-wiles.jpg",
    firstName: "Laura",
    lastName: "Wiles",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "Leio McLaren",
    firstName: "Leio",
    lastName: "McLaren",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/louise-villasmil.jpg",
    firstName: "Louise",
    lastName: "Villasmil",
    ssoId: "",
    isLearner: true,
  },
  {
    id: randomUUID(),
    email: "",
    headshot: "/demo/michaela-dam.jpg",
    firstName: "Michaela",
    lastName: "Dam",
    ssoId: "",
    isLearner: true,
  },
];

export function syncResponseSubjects(
  responseSubjects: ResponseSubject[],
  subjectUserIds: string[],
): ResponseSubject[] {
  responseSubjects = responseSubjects.filter(
    (sr) =>
      sr.subjectUserId !== null &&
      subjectUserIds.indexOf(sr.subjectUserId) >= 0,
  );
  const responseMap: Record<string, ResponseSubject> = {};
  responseSubjects.forEach((response) => {
    if (response.subjectUserId !== null) {
      responseMap[response.subjectUserId] = response;
    }
  });
  subjectUserIds.forEach((subjectUserId) => {
    if (responseMap[String(subjectUserId)]) {
      return;
    }
    responseMap[String(subjectUserId)] = {
      id: randomUUID(),
      completed: false,
      subjectType: ResponseSubjectType.Peer,
      subjectUserId: subjectUserId,
      answers: [],
      isTeamLead: false,
    };
  });
  return Object.values(responseMap);
}

export function syncResponses(original: Data): Data {
  const data = { ...original };
  data.campaigns.forEach((campaign) => {
    const responseMap: Record<string, Response> = {};
    const newResponseMap: Record<string, Response> = {};
    const campaignResponses = data.responses.filter(
      (response) => response.campaignId === campaign.id,
    );
    const nonCampaignResponses = data.responses.filter(
      (response) => response.campaignId !== campaign.id,
    );
    campaignResponses.forEach((response) => {
      responseMap[response.authorId] = response;
    });

    const teams = data.teams;

    teams.forEach((team) =>
      team.userIds.forEach((authorId) => {
        const existingResponse = responseMap[String(authorId)];
        if (existingResponse) {
          const response = {
            ...existingResponse,
            responseSubjects: syncResponseSubjects(
              existingResponse.responseSubjects,
              team.userIds,
            ),
          };
          newResponseMap[String(authorId)] = response;
          return;
        }
        const response = {
          id: randomUUID(),
          authorId,
          campaignId: campaign.id,
          groupId: "",
          submitted: false,
          submittedDate: new Date(),
          hasAnswers: false,
          hasReceivedFeedback: false,
          isTeamLead: false,
          responseSubjects: syncResponseSubjects([], team.userIds),
          tokenizedLink: "",
        };
        newResponseMap[String(authorId)] = response;
      }),
    );
    data.responses = [
      ...nonCampaignResponses,
      ...Object.values(newResponseMap),
    ];
  });

  return data;
}

export function addRandomResponses(original: Data): Data {
  const clone = { ...original };
  clone.responses = clone.responses.map((response) => {
    const campaign = clone.campaigns.find(
      (target) => target.id === response.campaignId,
    );
    const template = clone.templates.find(
      (target) => target.id === campaign?.formId,
    );
    return {
      ...response,
      submitted: true,
      responseSubjects: response.responseSubjects.map((responseSubject) => {
        // const answers: Record<string, number> = {};
        const answers: Answer[] = [];
        template?.items.forEach((question) => {
          answers.push({
            id: randomUUID(),
            itemId: question.id,
            modified: new Date().toISOString(),
            value: weightedRandom(standardOptions, standardWeights) as number,
          });
        });
        return {
          ...responseSubject,
          completed: true,
          answers,
        };
      }),
    };
  });

  return clone;
}

(window as unknown as Window & { respond: () => void }).respond = () => {
  const data = JSON.parse(window.sessionStorage.getItem("data") ?? "");
  window.sessionStorage.setItem(
    "data",
    JSON.stringify(addRandomResponses(data)),
  );
};

export function importTeams(data: Data, section: Section): Data {
  const extraUserIds = extraUsers.map((u) => u.id);
  const clone = { ...data };
  const newTeam = {
    ...getNewTeam(),
    userIds: extraUserIds,
    label: "Team C",
  };
  clone.users = getUnique([...clone.users, ...extraUsers]);
  clone.sections = clone.sections.map((target) =>
    target.id === section.id
      ? {
          ...section,
          userIds: getUnique([...section.userIds, ...extraUserIds]),
        }
      : target,
  );
  clone.teams = [...clone.teams, newTeam];
  return clone;
}

export function getLikertOptions(): ItemOption[] {
  return options.map((o) => ({ ...o, id: randomUUID() }));
}

export function getYesNoOptions(): ItemOption[] {
  return yesno.map((o) => ({ ...o, id: randomUUID() }));
}

export function getMostRecentChoiceItem(template: Template, ignoreId?: string) {
  let filtered = template.items.filter((x) => x.itemType === ItemType.Choice);
  if (ignoreId) {
    filtered = filtered.filter((x) => x.id !== ignoreId);
  }
  const sorted = filtered.sort((a, b) => b.order - a.order);
  return sorted[0];
}

export function copyOptions(formItem: FormItem): ItemOption[] {
  return formItem.options.map((o) => ({ ...o, id: randomUUID() }));
}

export function hasIdenticalOptions(
  targetOptions: ItemOption[],
  options: ItemOption[],
) {
  if (targetOptions.length !== options.length) {
    return false;
  }
  for (let lcv = 0; lcv < options.length; lcv++) {
    const option = options[lcv];
    const targetOption = targetOptions[lcv];
    if (option.label !== targetOption.label) {
      return false;
    }
    if (option.value !== targetOption.value) {
      return false;
    }
  }
  return true;
}
