import React, { useEffect, useState } from "react";
import { colors, Fab, CircularProgress } from "@mui/material";
import { Check, Schedule, Edit } from "@mui/icons-material";
import { User } from "../types";
import { UserAvatar } from "./User/UserAvatar";

const DEFAULT_AVATAR = "/images/messages/avatar-50.png";

const fabComplete = {
  bgcolor: colors.green[500],
  "&:hover": {
    bgcolor: colors.green[700],
  },
};

const fabIncompleteActive = {
  bgcolor: colors.yellow[500],
  "&:hover": {
    bgcolor: colors.yellow[700],
  },
};

const fabIncompleteInactive = {
  bgcolor: colors.grey[500],
  "&:hover": {
    bgcolor: colors.grey[700],
  },
};

export interface Props {
  active: boolean;
  completed: boolean;
  divProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  size: "large" | "medium" | "small" | undefined;
  user?: User;
}

export const BlingCheckmark = ({
  active,
  completed,
  divProps,
  size,
  user,
}: Props) => {
  // todo: get the progress bar working on other sizes
  const initial = size === "small" ? 0 : 200;
  const [loading, setLoading] = useState(initial);

  const showHeadshot =
    (completed || active) && user && !user.headshot.endsWith(DEFAULT_AVATAR);

  useEffect(() => {
    if (loading < 200) {
      setTimeout(() => setLoading(loading + 20), 100);
    }
  }, [loading, setLoading]);

  useEffect(() => {
    setLoading(initial);
  }, [completed, initial]);

  return (
    <div {...divProps}>
      <Fab
        aria-label="save"
        sx={
          completed && loading >= 100
            ? fabComplete
            : active
              ? fabIncompleteActive
              : fabIncompleteInactive
        }
        size={size}
      >
        {showHeadshot && (
          <UserAvatar
            sx={{ border: "2px solid rgba(0,0,0,0)", width: 36, height: 36 }}
            user={user}
          />
        )}
        {!showHeadshot &&
          (active ? <Edit /> : completed ? <Check /> : <Schedule />)}
      </Fab>
      {completed && loading < 200 && size === "small" && (
        <CircularProgress
          size={48}
          variant="determinate"
          value={Math.min(loading, 100)}
          sx={{
            color: colors.green[500],
            position: "absolute",
            top: 12,
            left: 12,
            zIndex: 1,
            opacity: loading > 100 ? (150 - loading) / 50 : 1,
          }}
        />
      )}
    </div>
  );
};
