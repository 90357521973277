import React, { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { ThinLayout } from "../Layouts/ThinLayout";
import { ResponseEdit } from "../Components/Response/Edit";
import {
  ItemRecipientType,
  ItemSubjectType,
  ResponseSubjectType,
  User,
} from "../types";
import { Close, Publish } from "@mui/icons-material";
import randomUUID from "../UUID";
import { useQuery } from "@apollo/client";
import { GET_FORM, FormResult } from "../Queries";
import { useNavigate } from "react-router-dom";

export const PreviewTemplate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();

  //
  // State
  //

  const [submitted, setSubmitted] = useState(false);
  const [viewAs, setViewAs] = useState<"peer" | "lead" | "nonlead">("peer");

  //
  // Queries
  //

  const { loading: formLoading, data: formData } = useQuery<FormResult>(
    GET_FORM,
    { variables: { pk: templateId } },
  );

  const template = formData ? formData.form : undefined;
  const showLoading = formLoading;

  const users: User[] = [
    {
      id: randomUUID(),
      email: "",
      firstName: "Student",
      lastName: "A",
      headshot: "",
      ssoId: "",
      isLearner: true,
    },
    {
      id: randomUUID(),
      email: "",
      firstName: "Student",
      lastName: "B",
      headshot: "",
      ssoId: "",
      isLearner: true,
    },
    {
      id: randomUUID(),
      email: "",
      firstName: "Student",
      lastName: "C",
      headshot: "",
      ssoId: "",
      isLearner: true,
    },
  ];

  const hasPeerSelfType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.PeerSelf,
  );
  const hasPeerOnlyType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.PeerOnly,
  );
  const hasLeadSelfType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.LeadSelf,
  );
  const hasLeadOnlyType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.LeadOnly,
  );
  const hasNonLeadSelfType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.NonLeadSelf,
  );
  const hasNonLeadOnlyType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.NonLeadOnly,
  );
  const hasGeneralType = template?.items.some(
    (item) => item.subjectType === ItemSubjectType.General,
  );

  const userSubjects =
    hasPeerSelfType ||
    hasPeerOnlyType ||
    hasNonLeadSelfType ||
    hasNonLeadOnlyType ||
    hasLeadSelfType ||
    hasLeadOnlyType
      ? (() => {
          const peerResponseUsers = new Set<string>();
          const isLead = viewAs === "lead";
          const author = users[0];

          // Handle peer feedback types
          if (hasPeerSelfType) {
            users.forEach((user) => peerResponseUsers.add(user.id));
          } else if (hasPeerOnlyType) {
            users
              .filter((u) => u.id !== author.id)
              .forEach((user) => peerResponseUsers.add(user.id));
          }

          // Handle lead feedback types
          if (hasLeadSelfType) {
            if (isLead) {
              // If user is leader, they evaluate themselves
              peerResponseUsers.add(author.id);
            } else {
              // If user is not leader, they evaluate the leader
              peerResponseUsers.add(users[1].id); // Student B is the leader
            }
          } else if (hasLeadOnlyType) {
            if (!isLead) {
              peerResponseUsers.add(users[1].id); // Student B is the leader
            }
          }

          // Handle non-lead feedback types
          if (hasNonLeadSelfType) {
            users
              .filter((u) => u.id !== users[1].id)
              .forEach((user) => peerResponseUsers.add(user.id));
          } else if (hasNonLeadOnlyType) {
            if (isLead) {
              users
                .filter((u) => u.id !== users[1].id)
                .forEach((user) => peerResponseUsers.add(user.id));
            } else {
              users
                .filter((u) => u.id !== users[1].id && u.id !== author.id)
                .forEach((user) => peerResponseUsers.add(user.id));
            }
          }

          return Array.from(peerResponseUsers).map((userId) => ({
            id: randomUUID(),
            answers: [],
            isTeamLead: userId === users[1].id, // Student B is the leader
            completed: true,
            subjectType: ResponseSubjectType.Peer,
            subjectUserId: userId,
          }));
        })()
      : [];

  const generalSubjects = hasGeneralType
    ? [
        {
          id: randomUUID(),
          answers: [],
          subjectType: ResponseSubjectType.General,
          subjectUserId: null,
          completed: true,
          isTeamLead: false,
        },
      ]
    : [];

  // Determine available recipient types
  const hasLeadTypes = template?.items.some(
    (item) => item.recipientType === ItemRecipientType.TeamLeaders,
  );
  const hasNonLeadTypes = template?.items.some(
    (item) => item.recipientType === ItemRecipientType.NonLeaders,
  );
  const hasPeerTypes = template?.items.some(
    (item) => item.recipientType === ItemRecipientType.TeamMembers,
  );

  // Calculate available roles for viewing
  const availableRoles = [
    ...(hasPeerTypes ? ["peer"] : []),
    ...(hasLeadTypes ? ["lead"] : []),
    ...(hasNonLeadTypes ? ["nonlead"] : []),
  ] as const;

  // If only one role is available, use it
  useEffect(() => {
    if (availableRoles.length === 1) {
      setViewAs(availableRoles[0] as typeof viewAs);
    }
  }, [template]);

  if (!template) {
    return (
      <ThinLayout title="Preview Template" loading={showLoading}>
        Template {templateId} not found
      </ThinLayout>
    );
  }

  // Update author based on viewAs selection
  const author = users[0];

  const response = {
    id: randomUUID(),
    campaignId: "",
    authorId: author.id,
    groupId: "",
    submitted: false,
    submittedDate: new Date(),
    hasAnswers: false,
    hasReceivedFeedback: false,
    isTeamLead: viewAs === "lead",
    responseSubjects: [...userSubjects, ...generalSubjects],
    tokenizedLink: "",
  };

  //
  // Event handlers
  //

  function handleResponseChange() {
    // todo: anything?
  }

  function handleSubmit() {
    setSubmitted(true);
  }

  //
  // Components
  //

  const RoleSelect = ({ roles }: { roles: Readonly<string[]> }) => {
    if (!roles || roles.length <= 1) {
      return null;
    }

    return (
      <FormControl size="small" sx={{ mt: 1, mr: 2, minWidth: 120 }}>
        <InputLabel>View as</InputLabel>
        <Select
          value={viewAs}
          label="View as"
          onChange={(e) => setViewAs(e.target.value as typeof viewAs)}
        >
          {availableRoles.map((role) => (
            <MenuItem key={role} value={role}>
              {role === "peer"
                ? "Peer"
                : role === "lead"
                  ? "Team Lead"
                  : "Non-Lead"}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <ThinLayout
      title={`${template.name}`}
      loading={showLoading}
      secondaryTitle={`You are previewing ${template.name}`}
      secondaryActionComponent={
        <>
          <RoleSelect roles={availableRoles} />
          <IconButton
            size="small"
            edge="end"
            aria-label="Close"
            sx={{ mr: 1 }}
            onClick={() => navigate(-1)}
          >
            <Close fontSize={"small"} />
          </IconButton>
        </>
      }
    >
      {submitted ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 200,
          }}
        >
          You&apos;re all done! Thanks for your feedback!
        </Box>
      ) : (
        <>
          <ResponseEdit
            response={response}
            author={author}
            subjects={users}
            formItems={template.items}
            onChange={handleResponseChange}
            instructions={template.instructions}
            extraTopMargin
          />
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            endIcon={<Publish />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </>
      )}
    </ThinLayout>
  );
};
