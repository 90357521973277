import React from "react";
import { Link, useParams } from "react-router-dom";
import { AnonAnalysisView } from "../Components/Analysis/AnonView";
import { getUserName } from "../DataTransform";
import { useQuery } from "@apollo/client";
import { ResultsResult, GET_RESULTS } from "../Queries";
import { ThinLayout } from "../Layouts/ThinLayout";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useErrorBoundary } from "../Contexts/ErrorContext";
import { useTranslation } from "react-i18next";

export const ResultsPreview = () => {
  const { campaignId, userId, source } = useParams();
  const { setError } = useErrorBoundary();
  const [t] = useTranslation();

  //
  // Queries
  //

  const { loading: resultsLoading, data: resultsData } =
    useQuery<ResultsResult>(GET_RESULTS, {
      variables: { id: campaignId, userId },
      fetchPolicy: "network-only", // Doesn't check cache before making a network request
      onError: (error) => setError(t("errors.results.list"), error),
    });

  const results = resultsData ? resultsData.results : undefined;

  const showLoading = resultsLoading;

  const campaign = results?.campaign;
  const user = results?.user;
  const analyses = results?.analyses;
  const aggregates = results?.aggregates;
  const overall = results?.overall;
  const template = results?.form;

  const title = `${user ? getUserName(user) : "Your"} results for ${
    campaign?.name
  }`;

  const secondaryTitle = `Previewing results as ${
    user ? getUserName(user) : ""
  }`;

  const returnUrl =
    source == "campaign"
      ? `/campaigns/${campaignId}`
      : `/results/${campaignId}//${user?.id}/`;

  return (
    <ThinLayout
      title={title}
      loading={showLoading}
      secondaryTitle={secondaryTitle}
      secondaryActionComponent={
        <IconButton
          edge="end"
          aria-label="close"
          component={Link}
          to={returnUrl}
        >
          <Close fontSize={"small"} />
        </IconButton>
      }
    >
      {template && campaign && user && analyses && aggregates && overall && (
        <AnonAnalysisView
          title={title}
          campaign={campaign}
          user={user}
          analyses={analyses}
          aggregates={aggregates}
          overall={overall}
          template={template}
          preview={true}
        />
      )}
    </ThinLayout>
  );
};
