import React, { useState } from "react";
import { Link, Stack, Typography } from "@mui/material";
import { useNavigate, useRouteError } from "react-router";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

export const ErrorMessage = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const error = useRouteError();

  //
  // State
  //

  const [showDetails, setShowDetails] = useState(false);

  const ErrorMessage = () => {
    if (error instanceof Error) {
      return <Typography variant="body1">{error.message}</Typography>;
    }
    const { data } = error as any;
    console.warn({ data });
    if (data) {
      return <Typography variant="body1">{data}</Typography>;
    }

    return null;
  };

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      height={"100vh"}
      gap={3}
    >
      <img src={"/logo-dark.svg"} width={250} alt="Feedback Loop logo" />

      <Typography variant="h6" textAlign={"center"}>
        {t("errorPage.title")}
      </Typography>

      <LoadingButton variant="contained" onClick={() => navigate("/")}>
        {t("errorPage.primaryCta")}
      </LoadingButton>

      {!showDetails && (
        <Link sx={{ cursor: "pointer" }} onClick={() => setShowDetails(true)}>
          {t("errorPage.secondaryCta")}
        </Link>
      )}
      {showDetails && <ErrorMessage />}
    </Stack>
  );
};
