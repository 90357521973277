import { red } from "@mui/material/colors";

export const MuiAlert = {
  styleOverrides: {
    root: {
      backgroundColor: red[700],
      borderRadius: "0",
      fontWeight: "bold",
      fontSize: "1rem",
      color: "white",
    },
  },
};
