import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { RouterProvider } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  useQuery,
} from "@apollo/client";
import DebounceLink from "apollo-link-debounce";
import { GET_ROLE, RoleResult } from "./Queries";
import { Loading } from "./Containers/Loading";
import { UserProvider } from "./Contexts/UserContext";
import { ErrorProvider } from "./Contexts/ErrorContext";
import { instructorRouter, studentRouter } from "./Routes";
import { initTheme } from "./Theme";

import "./i18n";

Sentry.init({
  dsn: "https://076f883fd2e5469dad63c1f740605813@o4505098553524224.ingest.sentry.io/4505098592583680",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const DEFAULT_DEBOUNCE_TIMEOUT = 250;

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      (key, value) => (key === "__typename" ? undefined : value),
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const link = ApolloLink.from([
  cleanTypeName,
  new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
  createHttpLink({
    uri: "/graphql",
    credentials: "same-origin",
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache({
    // addTypename: false,
  }),
  link,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function App() {
  // TODO: Fix chart themes so we can restore auto dark mode
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = true;
  const [mode, setMode] = React.useState<"dark" | "light">();

  //
  // Effects
  //

  React.useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode]);

  //
  // Queries
  //

  const { loading: roleLoading, data: roleData } = useQuery<RoleResult>(
    GET_ROLE,
    { client },
  );

  return (
    <ErrorProvider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={initTheme(mode)}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <UserProvider>
              {roleLoading ? (
                <Loading />
              ) : (
                <RouterProvider
                  router={
                    roleData?.myRole === "instructor"
                      ? instructorRouter
                      : studentRouter
                  }
                />
              )}
            </UserProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorProvider>
  );
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
