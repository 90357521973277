import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Layout } from "../Layouts/Layout";
import { TemplateCard } from "../Components/Template/Card";
import { useNavigate } from "react-router";
import { Add } from "@mui/icons-material";
import { Template } from "../types";
import {
  ADD_FORM,
  CLONE_FORM,
  DELETE_FORM,
  GET_FORMS,
  AddFormResult,
  CloneFormResult,
  FormsResult,
} from "../Queries";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useErrorBoundary } from "../Contexts/ErrorContext";

export const Templates = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { setError } = useErrorBoundary();

  //
  // Queries
  //

  const { loading: formsLoading, data: formsData } = useQuery<FormsResult>(
    GET_FORMS,
    {
      variables: { filters: {} },
      onError: (error) => setError(t("errors.forms.list"), error),
    },
  );

  //
  // Mutations
  //

  const [addForm] = useMutation<AddFormResult>(ADD_FORM, {
    refetchQueries: ["GetForms"],
    onError: (error) => setError(t("errors.forms.create"), error),
  });

  const [cloneForm] = useMutation<CloneFormResult>(CLONE_FORM, {
    refetchQueries: ["GetForms"],
    onError: (error) => setError(t("errors.forms.clone"), error),
  });

  const [deleteForm] = useMutation<boolean>(DELETE_FORM, {
    refetchQueries: ["GetForms"],
    onError: (error) => setError(t("errors.forms.delete"), error),
  });

  // TODO: shift this filtering to backend!!!!
  const templates = formsData
    ? formsData.forms.filter(
        (form) =>
          !form.isSnapshot &&
          (form.isMine || form.isDirectShare || form.isShared || form.isGlobal),
      )
    : [];

  //
  // Event Handlers
  //

  async function handleAddTemplate() {
    const { data: newFormResult } = await addForm({
      variables: { name: "New template" },
    });
    if (!newFormResult) {
      return;
    }
    const { addForm: newForm } = newFormResult;
    navigate(`/templates/${newForm.id}`);
  }

  async function handleCloneTemplate(template: Template) {
    const { data: clonedFormResult } = await cloneForm({
      variables: { id: template.id },
    });
    if (!clonedFormResult) {
      return;
    }
    const { cloneForm: clonedForm } = clonedFormResult;
    navigate(`/templates/${clonedForm.id}`);
  }

  async function handleTemplateDelete(newTemplate: Template) {
    try {
      const { data: deleteFormResult } = await deleteForm({
        variables: { id: newTemplate.id },
      });
      if (!deleteFormResult) {
        return;
      }
      navigate(`/templates`);
    } catch (e) {
      alert("Error deleting template; are you using it in a draft survey?");
    }
  }

  const showLoading = formsLoading;
  const yourTemplates = templates.filter((template) => template.isMine);
  const institutionTemplates = templates.filter(
    (template) =>
      template.isShared &&
      !template.isMine &&
      // !template.isDirectShare &&
      !template.isGlobal,
  );
  const globalTemplates = templates.filter(
    (template) =>
      template.isGlobal &&
      !template.isMine &&
      // !template.isDirectShare &&
      !template.isShared,
  );

  return (
    <Layout
      breadcrumbs={[{ title: t("breadcrumbs.templates") }]}
      loading={showLoading}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box />
        <Box>
          <Button
            variant="contained"
            aria-label="add template"
            endIcon={<Add />}
            onClick={handleAddTemplate}
          >
            {t("templates.add")}
          </Button>
        </Box>
      </Box>

      <Typography
        variant="h5"
        color="primary"
        sx={{ fontWeight: "bold", mb: 1 }}
      >
        {t("templates.your")}
      </Typography>
      <Grid container spacing={2}>
        {yourTemplates.map((template) => (
          <Grid item key={template.id} xs={12} md={6} lg={4}>
            <TemplateCard
              template={template}
              onClone={() => handleCloneTemplate(template)}
              onDelete={() => handleTemplateDelete(template)}
            />
          </Grid>
        ))}
      </Grid>

      {institutionTemplates.length > 0 && (
        <>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
          >
            {t("templates.institution")}
          </Typography>
          <Grid container spacing={2}>
            {institutionTemplates.map((template) => (
              <Grid item key={template.id} xs={12} md={6} lg={4}>
                <TemplateCard
                  template={template}
                  onClone={() => handleCloneTemplate(template)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {globalTemplates.length > 0 && (
        <>
          <Typography
            variant="h5"
            color="primary"
            sx={{ fontWeight: "bold", mb: 1, mt: 2 }}
          >
            {t("templates.global")}
          </Typography>
          <Grid container spacing={2}>
            {globalTemplates.map((template) => (
              <Grid item key={template.id} xs={12} md={6} lg={4}>
                <TemplateCard
                  template={template}
                  onClone={() => handleCloneTemplate(template)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Layout>
  );
};
