import React from "react";
import { useSearchParams } from "react-router-dom";
import { AnonAnalysisView } from "../Components/Analysis/AnonView";
import { getUserName } from "../DataTransform";
import { useQuery } from "@apollo/client";
import { ResultsResult, GET_RESULTS } from "../Queries";
import { ThinLayout } from "../Layouts/ThinLayout";
import { useErrorBoundary } from "../Contexts/ErrorContext";
import { useTranslation } from "react-i18next";

export const ResultsAnon = () => {
  const [searchParams] = useSearchParams();
  const { setError } = useErrorBoundary();
  const [t] = useTranslation();

  const key = searchParams.get("key");
  const resultsKeyBlob = atob(key || "");
  const resultsParts = resultsKeyBlob.split(":");

  const resultsId = resultsParts[0];
  const resultsToken = resultsParts[1];

  //
  // Queries
  //

  const { loading: resultsLoading, data: resultsData } =
    useQuery<ResultsResult>(GET_RESULTS, {
      variables: { id: resultsId },
      context: {
        headers: {
          Authorization: `JWT ${resultsToken}`,
        },
      },
      onError: (error) => setError(t("errors.results.list"), error),
    });

  const results = resultsData ? resultsData.results : undefined;

  const showLoading = resultsLoading;

  const campaign = results?.campaign;
  const user = results?.user;
  const analyses = results?.analyses;
  const aggregates = results?.aggregates;
  const overall = results?.overall;
  const template = results?.form;

  const title = `${user ? getUserName(user) : "Your"} results for ${
    campaign?.name
  }`;

  return (
    <ThinLayout title={title} loading={showLoading}>
      {template && campaign && user && analyses && aggregates && overall && (
        <AnonAnalysisView
          title={title}
          campaign={campaign}
          user={user}
          analyses={analyses}
          aggregates={aggregates}
          overall={overall}
          template={template}
          resultsToken={resultsToken}
        />
      )}
    </ThinLayout>
  );
};
