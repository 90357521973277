import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { MuiCard } from "./components/MuiCard";
import { MuiAlert } from "./components/MuiAlert";

export const initTheme = (mode?: "light" | "dark") => {
  const theme = createTheme({
    components: {
      MuiCard,
      MuiAlert,
    },
    palette:
      mode === "dark"
        ? {
            mode,
            primary: {
              // #4C3091
              main: "#7D5DCA",
            },
            secondary: {
              // #843193
              main: "#843193",
            },
          }
        : {
            mode,
            primary: {
              main: "#3a246f",
            },
            secondary: {
              main: "#411849",
            },
          },
  });

  return responsiveFontSizes(theme);
};
