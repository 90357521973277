import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { campaigns } from "./Data";

const resources = {
  en: {
    translation: {
      assignments: {
        selector: "Create as assignment",
        yes: "Yes",
        no: "No",
        points: "Max points",
        ratingProportion: "Grade weighting",
      },
      assignmentsGrading: {
        selector: "Grade this assignment",
        fixedAutomatic: "Auto graded",
        manual: "Grade manually in the LMS",
        mode: {
          FA: "Auto graded",
          MA: "Grade manually in the LMS",
        },
      },
      campaigns: {
        add: "Add Survey",
        autoCloseDate: "Scheduled close",
        autoReleaseDate: "Scheduled results release",
        autoSendDate: "Scheduled send",
        campaign: "Survey",
        confirmDelete: "Are you sure you want to delete {{name}} survey?",
        dueDate: "Due date",
        events: {
          assignmentChange: "Assignment creation {{assignment}}",
          assignmentGradingChange:
            "Assignment grading changed to {{assignmentGradingMode}}",
          assignmentPointsChange: "Assignment points changed to {{points}}",
          assignmentRatingProportionChange:
            "Assignment award weighting changed to {{assignmentRatingProportion}}",
          cancelSchedule: "Scheduled survey was cancelled",
          close: "Survey closed",
          closeDateChange: "Scheduled close date changed to {{date}}",
          dueDateChange: "Due date changed to {{date}}",
          loopStudentChange: "Closing the loop with students {{enabled}}",
          loopInstructorChange: "Closing the loop with instructors {{enabled}}",
          loopSettingsChange:
            "Loop settings updated - student: {{student}}, instructor: {{instructor}}",
          schedule: "Survey scheduled",
          send: "Survey sent",
          sendDateChange: "Scheduled send date changed to {{date}}",
          reopen: "Survey reopened",
          release: "Survey results released",
          releaseDateChange: "Scheduled release date changed to {{date}}",
          sectionChange: "Section changed to {{section}}",
          templateChange: "Library changed to {{template}}",
        },
        label: "Survey name",
        teamCount: "Teams included",
        teamSet: "Group category",
        loopSettings: "Loop Settings",
        loopInstructor: "Close the loop with instructors",
        loopStudent: "Close the loop with students",
        yes: "Yes",
        no: "No",
        groups: {
          active: "Active surveys",
          draft: "Draft surveys",
          closed: "Closed surveys",
          scheduled: "Scheduled surveys",
        },
      },
      courses: {
        course: "Course",
      },
      sections: {
        section: "Section",
      },
      templates: {
        add: "Add Template",
        global: "Feedback Loop templates",
        institution: "Institution shared templates",
        label: "Template Name",
        template: "Template",
        your: "Your templates",
        snapshot:
          "You're viewing a snapshot of this template as it was sent with a survey.",
      },
      categories: {
        category: "Group Category",
        label: "Group Category Name",
      },

      breadcrumbs: {
        campaigns: "Surveys",
        yourSurveys: "Your Surveys",
        templates: "Library",
        teams: "Teams",
        teamList: "Get Started",
      },
      navigation: {
        campaigns: "Surveys",
        teams: "Teams",
        templates: "Library",
      },
      errorPage: {
        title:
          "Sorry about that! Something went wrong. Please check the URL and try again.",
        primaryCta: "Back to Home",
        secondaryCta: "Show Details",
      },
      errors: {
        updateAssignmentPoints: "Failed to update points for assignment",
        forms: {
          get: "Failed to load form",
          list: "Failed to load form",
          create: "Failed to create a new form",
          update: "Failed to save changes to the form",
          delete: "Failed to delete the form",
          share: "Failed to share the form",
          clone: "Failed to clone the form",
        },
        sections: {
          list: "Failed to load sections",
        },
        users: {
          list: "Failed to load users",
        },
        campaigns: {
          get: "Failed to load the campaign",
          list: "Failed to load campaigns",
          create: "Failed to create a new campaign",
          update: "Failed to save changes to the campaign",
          delete: "Failed to delete the campaign",
          sendReminder: "Failed to schedule/send the campaign reminder",
          setGroupCategory: "Failed to set the category for the group",
          pushGrades: "Failed to post grades for the campaign",
        },
        groups: {
          create: "Failed to create new group",
          update: "Failed to save changes to the group",
          delete: "Failed to delete the group",
          list: "Failed to load groups",
          categories: {
            list: "Failed to load categories for group",
          },
          exclude: "Failed to exclude the group",
          setLeader: "Failed to set the group leader",
        },
        responses: {
          get: "Failed to load the response",
          list: "Failed to load responses for the campaign",
          update: "Failed to save changes to the response",
          delete: "Failed to delete the response",
          getSummary: "Failed to load summary of responses",
          currentUser: "Failed to load your responses",
        },
        results: {
          list: "Failed to load results for campaign",
        },
        courses: {
          get: "Failed to load course",
          sync: "Failed to sync the course information",
          setMode: "Failed to update the course's mode",
        },
        licenses: {
          claim: "Failed to claim license",
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
