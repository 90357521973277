import React from "react";
import { BarChart, Delete as DeleteIcon } from "@mui/icons-material";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";
import {
  Campaign,
  CampaignState,
  Response,
  Team,
  Team as TeamType,
  User,
} from "../../types";
import { Link } from "react-router-dom";
import { UserList } from "../User/List";
import { ResultsButton } from "./ResultButton";
import { PreviewButton } from "./PreviewButton";
import { RespondButton } from "./RespondButton";

export interface Props {
  campaign: Campaign;
  team: TeamType;
  responses: Response[];
  users: User[];
  onExclude: (campaign: Campaign, team: Team) => void;
}

export const CampaignTeam = ({
  campaign,
  team,
  responses,
  users,
  onExclude,
}: Props) => {
  function getUser(response: Response) {
    return users.find((user) => user.id === response.authorId);
  }

  function handleExcludeClick(team: Team) {
    onExclude(campaign, team);
  }

  const responseAuthors = responses
    .map(getUser)
    .filter((target) => target !== undefined) as User[];

  // const responseSubjectUsers = responses
  //   .flatMap((response) => response.responseSubjects.filter((rs) => rs.subjectUserId !== null).map((rs) => rs.subjectUserId))
  //   .map((id) => users.find((user) => user.id === id))
  //   .filter((target) => target !== undefined) as User[];

  const responseSubjectUsers = team.userIds
    .map((id) => users.find((user) => user.id === id))
    .filter((target) => target !== undefined) as User[];

  const nonAuthorUsers = responseSubjectUsers.filter(
    (user) => !responseAuthors.includes(user),
  );

  const allUsers = [...responseAuthors, ...nonAuthorUsers];

  const campaignDueDate = campaign.dueDate ? new Date(campaign.dueDate) : null;

  return (
    <Card>
      <CardHeader
        title={team.name}
        action={
          campaign.state === CampaignState.Draft ? (
            <IconButton
              edge="end"
              aria-label="exclude from campaign"
              // component={Link}
              // to={`/results/${campaign.id}/PR/${team.id}/`}
              sx={{ mr: "12px" }}
              onClick={() => handleExcludeClick(team)}
            >
              <DeleteIcon />
            </IconButton>
          ) : campaign.state === CampaignState.Scheduled ? (
            <></> // No button in scheduled mode
          ) : (
            <IconButton
              edge="end"
              aria-label="results"
              component={Link}
              to={`/results/${campaign.id}/PR/${team.id}/`}
              sx={{ mr: "12px" }}
            >
              <BarChart />
            </IconButton>
          )
        }
      />
      <CardContent>
        <UserList
          responses={responses}
          users={allUsers}
          loopResponses={
            campaign.loopStudent ? campaign.loopFeedbackSummary : []
          }
          campaignDueDate={campaignDueDate}
          UserActions={({ user }) =>
            campaign.state === CampaignState.Draft ||
            campaign.state === CampaignState.Scheduled ? (
              <></>
            ) : (
              <>
                <RespondButton
                  user={user}
                  responses={responses}
                  responseAuthors={responseAuthors}
                />
                <PreviewButton campaign={campaign} user={user} />
                <ResultsButton
                  campaign={campaign}
                  team={team}
                  user={user}
                  responses={responses}
                />
              </>
            )
          }
        />
      </CardContent>
    </Card>
  );
};
