import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AnalysisView } from "../Components/Analysis/View";
import { Layout } from "../Layouts/Layout";
import { CampaignSelector } from "../Components/Campaign/Selector";
import { TeamSelector } from "../Components/Team/Selector";
import { UserSelector } from "../Components/User/Selector";
import { getUserName } from "../DataTransform";
import { Response, User, Team, ResponseSubjectType } from "../types";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  SectionsResult,
  GET_SECTIONS,
  UsersResult,
  GET_USERS,
  ResponsesResult,
  GET_RESPONSES,
  CampaignsResult,
  GET_CAMPAIGNS,
  CampaignResult,
  GET_CAMPAIGN,
} from "../Queries";
import { SubjectTypeSelector } from "../Components/SubjectType/Selector";
import { Button, Switch, Stack, Typography, Box } from "@mui/material";
import isCompatible from "../Components/SubjectType/compatibility";
import { useErrorBoundary } from "../Contexts/ErrorContext";

export const Results = () => {
  const {
    campaignId,
    subjectType = ResponseSubjectType.Peer,
    teamId,
    userId,
  } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { setError } = useErrorBoundary();

  //
  // State
  //

  const [reverse, setReverse] = useState(false);

  //
  // Queries
  //

  const { loading: campaignsLoading, data: campaignsData } =
    useQuery<CampaignsResult>(GET_CAMPAIGNS, {
      variables: {
        filters: {},
      },
      onError: (error) => setError(t("errors.campaigns.list"), error),
    });

  const { loading: campaignLoading, data: campaignData } =
    useQuery<CampaignResult>(GET_CAMPAIGN, {
      variables: { pk: campaignId },
      onError: (error) => setError(t("errors.campaigns.get"), error),
    });

  const { loading: sectionsLoading, data: sectionsData } =
    useQuery<SectionsResult>(GET_SECTIONS, {
      variables: { filters: {} },
      onError: (error) => setError(t("errors.sections.list"), error),
    });

  const campaign = campaignData ? campaignData.campaign : null;
  // const campaign = campaigns.find((target) => target.id === campaignId);
  const sections = sectionsData ? sectionsData.sections : [];
  const section = sections.find((target) => target.id === campaign?.sectionId);

  const teams = campaign && campaign.groups ? campaign.groups : [];
  const team = teams.find((target) => target.id === teamId);

  // GET USERS
  const { loading: usersLoading, data: usersData } = useQuery<UsersResult>(
    GET_USERS,
    {
      variables: {
        filters: {
          sections: campaign
            ? campaign.sectionId
              ? campaign.sectionId
              : undefined
            : undefined,
        },
      },
      onError: (error) => setError(t("errors.users.list"), error),
    },
  );

  const allUsers = usersData ? usersData.users : [];

  let users: User[];
  if (team) {
    users = allUsers.filter((target) => team.userIds.indexOf(target.id) >= 0);
  } else if (section) {
    users = allUsers.filter(
      (target) => (section?.userIds.indexOf(target.id) ?? -1) >= 0,
    );
  } else {
    users = allUsers;
  }

  const user = users.find((target) => target.id === userId);

  // GET RESPONSES
  const { loading: responsesLoading, data: responsesData } =
    useQuery<ResponsesResult>(GET_RESPONSES, {
      variables: {
        filters: {
          campaignId,
        },
      },
      onError: (error) => setError(t("errors.responses.list"), error),
    });

  const campaigns = campaignsData ? campaignsData.campaigns : [];
  const allResponses = responsesData ? responsesData.responses : [];

  let responses: Response[] = [];
  responses = allResponses.filter(
    (target) =>
      users.map((t) => t.id).indexOf(target.authorId) >= 0 &&
      target.campaignId === campaignId,
  );
  // }

  const responseUsers =
    users.length > 0
      ? responses.map(
          (response) =>
            users.find((user) => user.id === response.authorId) as User,
        )
      : [];

  const subjectUsers =
    users.length > 0
      ? responses.flatMap((response) =>
          response.responseSubjects
            .filter((rs) => rs.subjectUserId !== null)
            .map(
              (responseSubject) =>
                users.find(
                  (user) => user.id === responseSubject.subjectUserId,
                ) as User,
            )
            .filter((user) => user !== undefined),
        )
      : [];

  // TODO: enforce uniqueness
  const combinedUsers = [...responseUsers, ...subjectUsers];

  const responseTeams =
    // responseUsers.length > 0
    combinedUsers.length > 0
      ? teams.filter((target) =>
          combinedUsers.find((userTarget) =>
            target.userIds.find((userId) => userId === userTarget.id),
          ),
        )
      : [];

  const template = campaign?.form;

  const hasPeerQuestions = template?.items.some((item) =>
    isCompatible(ResponseSubjectType.Peer, item.subjectType),
  );

  // Redirect to General Reflection if no peer questions exist
  React.useEffect(() => {
    if (
      template &&
      !hasPeerQuestions &&
      subjectType === ResponseSubjectType.Peer
    ) {
      changeUrl({
        changeType: ResponseSubjectType.General,
        changeTeam: "",
        changeUser: "",
      });
    }
  }, [template, hasPeerQuestions, subjectType]);

  const showLoading =
    campaignsLoading ||
    campaignLoading ||
    sectionsLoading ||
    usersLoading ||
    responsesLoading;

  if (!campaign) {
    return (
      <Layout breadcrumbs={[{ title: "Results" }]} loading={showLoading}>
        {showLoading ? <></> : <>Campaign not found</>}
      </Layout>
    );
  }

  function changeUrl({
    changeCampaign,
    changeType,
    changeTeam,
    changeUser,
  }: {
    changeCampaign?: string;
    changeType?: string;
    changeTeam?: string;
    changeUser?: string;
  }) {
    navigate(
      `/results/${changeCampaign ?? campaignId}/${changeType ?? subjectType}/${
        changeTeam ?? teamId ?? ""
      }/${changeUser ?? userId ?? ""}`,
    );
  }

  const title =
    `${campaign.name} Results` + (user ? ` of ${getUserName(user)}` : "");

  const previewUrl = `/results/preview/${campaignId}/${userId}/results`;

  return (
    <Layout
      actionComponent={
        user && (
          <Button aria-label="preview results" component={Link} to={previewUrl}>
            Preview Released View
          </Button>
        )
      }
      breadcrumbs={[
        {
          title,
        },
      ]}
      loading={showLoading}
    >
      <Box sx={{ mb: 2 }}>
        <CampaignSelector
          campaigns={campaigns}
          selected={campaign}
          label={t("campaigns.campaign")}
          onChange={(target) =>
            changeUrl({
              changeCampaign: target.id,
              changeType: ResponseSubjectType.Peer,
              changeTeam: "",
              changeUser: "",
            })
          }
          sx={{ mr: 2, mb: 2 }}
        />
        <SubjectTypeSelector
          form={template}
          selected={subjectType as ResponseSubjectType}
          label={"Select type"}
          onChange={(target) =>
            changeUrl({
              changeType: target,
              changeTeam: "",
              changeUser: "",
            })
          }
          sx={{ mr: 2, mb: 2 }}
        />
        <TeamSelector
          teams={[{ id: "", name: "Select Team" } as Team, ...teams]}
          selected={team}
          label="Team"
          onChange={(target) =>
            changeUrl({ changeTeam: target.id, changeUser: "" })
          }
          sx={{ mr: 2, mb: 2 }}
        />
        <UserSelector
          users={[
            { id: "", firstName: "Select", lastName: "User" } as User,
            ...users,
          ]}
          selected={user}
          label="Users"
          onChange={(target) => changeUrl({ changeUser: target.id })}
          sx={{ mr: 2, mb: 2 }}
        />
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Feedback Received</Typography>
          <Switch
            checked={reverse}
            onChange={(e) => setReverse(e.target.checked)}
          />
          <Typography>Given</Typography>
        </Stack>
      </Box>
      {template && responseUsers && responseTeams && responses && (
        <AnalysisView
          responses={responses}
          reverse={reverse}
          subjectType={subjectType as ResponseSubjectType}
          template={template}
          title={title}
          users={combinedUsers}
          user={user}
          teams={responseTeams}
          showStudents={!!team || !!user}
          showQuestions={!!user}
          onTeamClick={(teamId) =>
            changeUrl({ changeTeam: teamId, changeUser: "" })
          }
          onUserClick={(userId) =>
            changeUrl({ changeTeam: "", changeUser: userId })
          }
          campaign={campaign}
        />
      )}
    </Layout>
  );
};
