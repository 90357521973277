import React, { useEffect } from "react";
import { captureException, wrapCreateBrowserRouter } from "@sentry/react";
import { Teams } from "./Containers/Teams";
import { UserResponse } from "./Containers/UserResponse";
import { Templates } from "./Containers/Templates";
import { EditTemplate } from "./Containers/EditTemplate";
import { Campaigns } from "./Containers/Campaigns";
import { EditCampaign } from "./Containers/EditCampaign";
import { Results } from "./Containers/Results";
import { PreviewTemplate } from "./Containers/PreviewTemplate";
import { OpenResponses } from "./Containers/OpenResponses";
import { ResultsAnon } from "./Containers/ResultsAnon";
import { ResultsPreview } from "./Containers/ResultsPreview";
import { OAuth } from "./Containers/OAuth";
import { ErrorMessage } from "./Containers/ErrorMessage";
import {
  createBrowserRouter,
  useNavigate,
  useRouteError,
} from "react-router-dom";

function Navigate({ to }: { to: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return <></>;
}

function ErrorHandler() {
  const routeError = useRouteError();

  useEffect(() => {
    captureException(routeError, { level: "fatal" });
  }, [routeError]);

  return <ErrorMessage />;
}

export const instructorRouter = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: "/",
    errorElement: <ErrorHandler />,
    children: [
      {
        path: "/",
        element: <Navigate to="/teams" />,
      },
      {
        path: "templates",
        element: <Templates />,
      },
      {
        path: "templates/:templateId",
        element: <EditTemplate />,
      },
      {
        path: "campaigns",
        element: <Campaigns />,
      },
      {
        path: "campaigns/:campaignId",
        element: <EditCampaign />,
      },
      {
        path: "oauth/redirect",
        element: <OAuth />,
      },
      {
        path: "results/preview/:campaignId/:userId/:source",
        element: <ResultsPreview />,
      },
      {
        path: "results/:campaignId/:subjectType?/:teamId?/:userId?",
        element: <Results />,
      },
      {
        path: "results/:campaignId/:subjectType?//:userId?",
        element: <Results />,
      },
      {
        path: "results",
        element: <ResultsAnon />,
      },
      {
        path: "preview-template/:templateId",
        element: <PreviewTemplate />,
      },
      {
        path: "response",
        element: <UserResponse />,
      },
      {
        path: "teams",
        element: <Teams />,
      },
    ],
  },
]);

export const studentRouter = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorHandler />,
    children: [
      {
        path: "/",
        element: <Navigate to="/campaigns" />,
      },
      {
        path: "campaigns",
        element: <OpenResponses />,
      },
      {
        path: "results",
        element: <ResultsAnon />,
      },
      {
        path: "response",
        element: <UserResponse />,
      },
    ],
  },
]);
